import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "App";
import { AuthContextProvider } from "contexts/AuthContext";
import { AppContextProvider } from "contexts/AppContext";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import AuthService from "services/AuthService";

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      const userInfo = localStorage.getItem("user");

      if (userInfo) {
        AuthService.logout();
        window.location.href = "/login";
      }

      return Promise.reject(err);
    }
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
