import React from "react";
import { useNavigate } from "react-router-dom";
import { LogoWrapper } from "../../styles/styles";

import VSPLogo from "assets/0-THE-LOGO.png";
import { LogoPropType } from "util/types/props.types";
import { ROOT_PATH } from "util/paths";

const Logo: React.FC<LogoPropType> = ({ vertical, size = "normal" }) => {
  const width = size === "large" ? 90 : 70;
  const height = size === "large" ? 100 : 80;
  const navigate = useNavigate();

  return (
    <LogoWrapper
      vertical={vertical}
      size={size}
      onClick={size === "normal" ? () => navigate(ROOT_PATH) : () => null}
      pointer={size === "normal"}
      style={{ margin: "10px 0 " }}
    >
      <img src={VSPLogo} width={width} height={height} alt="vsp-logo" />
      <h1> Inventory Management</h1>
    </LogoWrapper>
  );
};

export default Logo;
