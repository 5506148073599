import React, { useCallback, useContext, useEffect, useState } from "react";
import { Timeline, Typography, Tag, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import withError from "hocs/withError";
import { HistoryPropType } from "util/types/props.types";
import { appContext } from "contexts/AppContext";
import { AppContextType } from "util/types/context.types";
import { Device } from "util/types/generics.types";
import { UserHistoryRes } from "util/types/response.types";
import { getUserHistory } from "api/api";
import { FlexContainer } from "styles/styles";
import { getDevices as getDevicesApi } from "api/api";
import { DEVICES_PATH } from "util/paths";

const UserHistory: React.FC<HistoryPropType> = ({ id, userName }) => {
  const { devices, setDevices } = useContext(appContext) as AppContextType;
  const [history, setHistory] = useState<Device[]>([]);
  const navigate = useNavigate();

  const getIdsOfOwnedDevices = useCallback((history: UserHistoryRes[]) => {
    const ids = history.map((log) => log.device_id);
    return Array.from(new Set(ids));
  }, []);

  const createUserHistroy = useCallback(
    (deviceIds: number[]) => {
      const ownedDevices: Device[] = [];
      deviceIds.forEach((id) => {
        const device = devices.find((device) => device.id === id);
        if (device) {
          ownedDevices.push(device);
        }
      });
      return ownedDevices;
    },
    [devices]
  );

  const getHistory = useCallback(async () => {
    const response = await getUserHistory(id);
    if (response !== "error") {
      const ownedDeviceIds = getIdsOfOwnedDevices(response);
      const history = createUserHistroy(ownedDeviceIds);
      setHistory(history);
    }
  }, [id, setHistory, getIdsOfOwnedDevices, createUserHistroy]);

  const getDevices = useCallback(async () => {
    const response = await getDevicesApi();
    if (response !== "error") {
      setDevices(response);
    }
  }, [setDevices]);

  const openDeviceHandler = (deviceId: number) => {
    navigate(`${DEVICES_PATH}/${deviceId}`);
  };

  const renderTimelineItemReturnDate = (
    date: moment.Moment | null,
    owner: string
  ) => {
    if (owner !== userName) {
      if (date) {
        return (
          <Tag color="blue" style={{ borderRadius: "25px" }}>
            {date.format("YYYY-DD-MM")}
          </Tag>
        );
      }
    } else if (owner === userName) {
      return (
        <Typography.Text type="success" strong>
          Now owning
        </Typography.Text>
      );
    }
  };

  const renderTimelineItem = (device: Device) => {
    return (
      <Timeline.Item key={device.id}>
        {renderTimelineItemReturnDate(device.return_date, device.owner)}
        <p>{device.name}</p>
        <p>{device.serial_number}</p>
        <Button
          onClick={() => openDeviceHandler(device.id)}
          icon={<ArrowRightOutlined />}
          shape="circle"
        />
      </Timeline.Item>
    );
  };

  const renderTimeline = () => {
    return (
      <Timeline mode="alternate" style={{ width: "100%" }}>
        {history.map((device) => {
          return device && renderTimelineItem(device);
        })}
      </Timeline>
    );
  };

  useEffect(() => {
    if (devices.length === 0) {
      getDevices();
    } else {
      getHistory();
    }
  }, [devices.length, getDevices, getHistory]);

  return history && history.length > 0 ? (
    <FlexContainer
      justify="center"
      align="center"
      direction="column"
      rowGap="1em"
      my="2em"
      mx="0"
    >
      <Typography.Title level={2}>Previously owned devices</Typography.Title>
      {renderTimeline()}
    </FlexContainer>
  ) : null;
};

export default withError(UserHistory);
