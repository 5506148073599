/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { authContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import authService from 'services/AuthService'
import { AuthContextType } from 'util/types/context.types'

const withError = <P extends object>(Component: React.ComponentType<P>) => { 
    return (props: any) => {
        const { activeUser } = useContext(authContext) as AuthContextType

        // user gets 403 error when is_logged_in cookie is erased, but still there is activeUser set
        if (!authService.isAuthenticated() && activeUser) {
            throw new Error('Session expired')
        }

        return <Component {...props} />
    }
}

export default withError