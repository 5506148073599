import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

import Devices from "views/Devices";
import withError from "hocs/withError";
import withAuth from "hocs/withAuth";
import {
  getDevices as getDevicesApi,
  deleteDevice as deleteDeviceApi,
  uploadDevices,
} from "api/api";
import { appContext } from "contexts/AppContext";
import { AppContextType } from "util/types/context.types";
import { DEVICES_PATH } from "util/paths";

const DevicesContainer: React.FC = () => {
  const {
    devices,
    formVisibility,
    setDevices,
    showAddDeviceForm,
    showAssignDeviceForm,
    showReturnDeviceForm,
    showDeleteDeviceForm,
  } = useContext(appContext) as AppContextType;
  const [uploadingDevices, setuUploadingDevices] = useState<boolean>(false);
  const navigate = useNavigate();

  const getDevices = useCallback(async () => {
    const response = await getDevicesApi();
    if (response !== "error") {
      setDevices(response);
    }
  }, [setDevices]);

  const deleteDeviceHandler = async (deviceId: number) => {
    const response = await deleteDeviceApi(deviceId);
    if (response !== "error") {
      setDevices((prevDevices) =>
        prevDevices.filter((device) => device.id !== deviceId)
      );
      message.success("Device Deleted Succesfully");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadDevicesHandler = async (data: any) => {
    setuUploadingDevices(true);
    const response = await uploadDevices(data);
    if (response !== "error") {
      setDevices((prevDevices) => [...prevDevices, ...response]);
      setuUploadingDevices(false);
    }
  };

  const openDeviceHandler = (deviceId: number) => {
    navigate(`${DEVICES_PATH}/${deviceId}`);
  };

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  return (
    <Devices
      devices={devices}
      uploadingDevices={uploadingDevices}
      formVisibility={formVisibility}
      setUploadingDevices={setuUploadingDevices}
      showAddDeviceForm={showAddDeviceForm}
      showAssignDeviceForm={showAssignDeviceForm}
      showReturnDeviceForm={showReturnDeviceForm}
      showDeleteDeviceForm={showDeleteDeviceForm}
      onDeleteDevice={deleteDeviceHandler}
      onUploadDevices={uploadDevicesHandler}
      onOpenDevice={openDeviceHandler}
    />
  );
};

export default withError(withAuth(DevicesContainer));
