import React from 'react'

import { LoginPropType } from 'util/types/props.types'
import { CustomSpin, LoginButton, LoginPageWrapper } from 'styles//styles'
import Logo from 'components/common/Logo'

const Login: React.FC<LoginPropType> = ({ loginUrl }) => { 
    const renderLoginForm = () => {
        return (
            <>
                <Logo 
                    size='large' 
                    vertical
                />
                <LoginButton href={loginUrl ?? ''}>Login</LoginButton>
            </>
        )
    }
    
    return (
        <LoginPageWrapper>
            {loginUrl? renderLoginForm() : <CustomSpin size='large'/>}
        </LoginPageWrapper>
    )
}

export default Login