import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Profile from 'views/Profile'
import withError from 'hocs/withError'
import withAuth from 'hocs/withAuth'
import { Device, User } from 'util/types/generics.types'
import { changeUserRole, getUserById, getUserDevices } from 'api/api'
import { CustomSpin } from 'styles/styles'
import { DEVICES_PATH } from 'util/paths'

const UserContainer: React.FC = () => { 
    const [user, setUser] = useState<User | null>(null)
    const [userDevices, setUserDevices] = useState<Device[] | []>([])
    const { userId } = useParams()
    const navigate = useNavigate()
    
    const getUser = useCallback(async () => {
        const response = await getUserById(userId)
        if (response !== 'error') { 
            setUser(response)
        }
    }, [userId, setUser])

    const getDevices = useCallback(async () => {
        const response = await getUserDevices(userId)
        if (response !== 'error') {
            setUserDevices(response)
        }
    }, [userId, setUserDevices])

    const selectDeviceHandler = async (deviceId: number) => {
        navigate(`${DEVICES_PATH}/${deviceId}`)
    }

    const roleChangeHandler = async () => {
        if (user) { 
            const role = user.role === 'admin'? 'user' : 'admin'
            const response = await changeUserRole(user.id, role)
            if (response !== 'error') {
                setUser(response)
            }
        } 
    }

    useEffect(() => {
        getUser()
        getDevices()
    }, [getUser, getDevices ])
    
    return (
        !user ?
            <CustomSpin size='large' /> :
            (
                <Profile
                    user={user}
                    devices={userDevices}
                    ownProfile={false}
                    onRoleChange={roleChangeHandler}
                    onDeviceSelected={selectDeviceHandler}
                />
            )
    )
}

export default withError(withAuth(UserContainer))