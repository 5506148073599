/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import Navigation from 'components/common/Navgation'
import Layout from 'components/common/Layout'

const withLayout = <P extends object>(Component: React.ComponentType<P>) => { 
    
    return (props: any) => { 
        return (
            <Layout 
                navigation={<Navigation />}
                body={<Component {...props}/>}
            />
        )
    }
}

export default withLayout