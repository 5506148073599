import { confirmDeviceReceived, confirmDeviceReturned } from "api/api";
import { CONFIRMATION_TYPES } from "util/constants";

const CONFIRMATION_TOKEN = "confirmationToken";
const CONFIRMATION_PATH = "confirmationPath";

class ConfirmationService {
  private clearConfirmationToken() {
    localStorage.removeItem(CONFIRMATION_TOKEN);
  }

  private async sendConfirmationRequest(
    confirmationType: CONFIRMATION_TYPES,
    confirmationToken: string
  ) {
    switch (confirmationType) {
      case CONFIRMATION_TYPES.RECEIVED:
        const response = await confirmDeviceReceived(confirmationToken);
        return response;
      //break;
      case CONFIRMATION_TYPES.RETURNED:
        await confirmDeviceReturned(confirmationToken);
        break;
      default:
      //
    }
  }

  setConfirmationToken(token: string) {
    localStorage.setItem(CONFIRMATION_TOKEN, token);
  }

  setConfirmationPath(path: string) {
    localStorage.setItem(CONFIRMATION_PATH, path);
  }

  getConfirmationTokenFromStorage() {
    return localStorage.getItem(CONFIRMATION_TOKEN);
  }

  getConfirmationPath() {
    return localStorage.getItem(CONFIRMATION_PATH);
  }

  isConfirmationPending() {
    return !!localStorage.getItem(CONFIRMATION_TOKEN);
  }

  async confirmAction(confirmationType: CONFIRMATION_TYPES, token: string) {
    // let confirmationToken = ''
    // if (this.isConfirmationPending()) {
    //     confirmationToken = this.getConfirmationTokenFromStorage() as string
    // } else {
    //     confirmationToken = token
    // }
    const response = await this.sendConfirmationRequest(
      confirmationType,
      token
    );
    this.clearConfirmationToken();
    return response;
  }
}

export default new ConfirmationService();
