/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Button, Result } from 'antd'

import withRouter from 'hocs/withRouter'
import { LOGIN_PATH } from 'util/paths'
import { Outlet } from 'react-router-dom'
import { authContext } from 'contexts/AuthContext'

type StateType = { 
    hasError: boolean
} 

class ErrorBoundary extends React.Component<any, StateType> { 
    static contextType = authContext
    context!: React.ContextType<typeof authContext>

    constructor(props: any) { 
        super(props)
        this.state = {hasError: false}
        this.returnToAppHandler = this.returnToAppHandler.bind(this)
    }
    
    componentDidCatch() { 
        this.setState({hasError: true})
    }

    async logout() { 
        if(this.context) {
            this.context.setActiveUser(null)
        }
    }

    async returnToAppHandler() {
        await this.logout()
        this.props.navigate(LOGIN_PATH)
    }

    render403Error() {
        return (
            <Result 
                status='403'
                title='403'
                subTitle='Your Session Expired'
                extra={
                    <Button 
                        size='large'
                        onClick={this.returnToAppHandler}
                        // to={LOGIN_PATH}
                        key={1}
                    >
                        Go to app
                    </Button>
                }
            />
        )
    }

    render() {
        if (this.state.hasError && this.context?.activeUser)  {
            return this.render403Error()
        }
        return <Outlet />
    }
}

export default withRouter(ErrorBoundary)