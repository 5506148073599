import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button, Spin, Tag, List } from "antd";

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5em;
  padding: 0.3em 0;
  background-color: rgba(0, 0, 0, 0.03);
`;

export const NavMenuWrappe = styled.div<{ cols?: number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.cols}, 1fr)`};
`;

export const BodyWrapper = styled.div`
  margin: 0 8em;
`;

export const CustomNavLink = styled(NavLink)`
  margin: 0 5px;
  border-radius: 25px;
  padding: 1em 2em;
  text-align: center;
  border: 1px solid transparent;
  text-decoration: none;
  font-weight: bold;
  transition: border 150ms ease;

  &:hover {
    border: 1px solid #64a64e;
    transition: border 300ms ease;
  }
`;

export const CustomListItem = styled(List.Item)<{ count: number }>`
  cursor: pointer;
  padding: 1em;
  transition: all 150ms ease;

  &:hover {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.26);
    transition: all 300ms ease;
  }

  h4 {
    color: ${(props) => (props.count === 0 ? "red" : "")};
  }
`;

export const LogoutButton = styled(Button)`
  border-radius: 25px;
  border-color: transparent;
  color: #1990ff;
  background-color: transparent;
  padding: 1em 2em;
  text-align: center;
  font-weight: bold;
  height: 100%;
  margin-left: 5px;
`;

export const DefaultVSPButton = styled(Button)<{
  logout?: boolean;
  htmlType?: string;
}>`
  ${(props) => {
    if (props.logout) {
      return `
                border-color: transparent;
                // border-color: #1990FF;
                color: #1990FF;
                background-color: transparent;
                padding: 1em 2em;
                text-align: center;
                font-weight: bold;
                height: 100%; 1990FF;
                margin-left: 1em;
            `;
    } else {
      return `
            border-radius: 25px;
            background-color: #46587c;
            border-color: #46587c;
            transition: opacity 150ms ease;
            color: white;
            &:hover { 
                background-color: #46587c;
                border-color: #46587c;
                color: white;
                opacity: 0.8;
                transition: opacity 300ms ease;
            }

            &:focus { 
                background-color: #46587c;
                border-color: #46587c;
                color: white;
            }
            `;
    }
  }}
`;

export const LoginButton = styled.a`
  border-radius: 25px;
  padding: 1em 2em;
  background-color: #46587c;
  border-color: #46587c;
  color: white;
  font-weight: bold;
  transition: opacity 150ms ease;
  text-transform: uppercase;

  &:hover {
    background-color: #46587c;
    border-color: #46587c;
    color: white;
    opacity: 0.8;
    transition: opacity 300ms ease;
  }
`;

export const AvailabilityStatus = styled.p<{
  emphasise: boolean;
  uppercase?: boolean;
  available: boolean;
  baseColor?: string;
}>`
  font-weight: ${(props) => (props.emphasise ? "bold" : "normal")};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "capitalize")};
  color: ${(props) => (props.available ? props.baseColor : "#FF4D4E")};
`;

export const LoginPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div<{
  vertical: boolean;
  pointer: boolean;
  size: string;
}>`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "riw")};
  justify-content: center;
  column-gap: 1em;
  align-items: center;
  cursor: ${(props) => (props.pointer ? "pointer" : "")};

  h1 {
    margin-left: 10px;
    color: #46587c;
    font-weight: bold;
    font-size: ${(props) => (props.size === "large" ? "3em" : "2em")};
  }
`;

export const CustomSpin = styled(Spin)`
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexContainer = styled.div<{
  direction?: string;
  justify?: string;
  align?: string;
  rowGap?: string;
  colGap?: string;
  self?: string;
  ml?: string;
  mb?: string;
  mt?: string;
  mr?: string;
  my?: string;
  mx?: string;
  height?: string;
  width?: string;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  align-self: ${(props) => props.self};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.colGap};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  margin: ${(props) => `${props.my} ${props.mx}`};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const GridContainer = styled.div<{
  rows?: string;
  cols?: string;
  rowGap?: string;
  colGap?: string;
  centered?: boolean;
  mb?: string;
}>`
  display: grid;
  grid-template-rows: ${(props) =>
    props.rows ? `repeat(${props.rows}, 1fr)` : "1fr"};
  grid-template-columns: ${(props) =>
    props.cols ? `repeat(${props.cols}, 1fr)` : "1fr"};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.colGap};
  justify-items: ${(props) => (props.centered ? "center" : "")};
  align-items: ${(props) => (props.centered ? "center" : "")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
`;

export const DeviceLabel = styled(Tag)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ChartWrapper = styled.div<{ width?: string; height?: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
