/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useNavigate } from 'react-router-dom'

const withRouter = <P extends object>(Component: React.ComponentType<P>) => { 
    return (props: any) => { 
        const navigate = useNavigate()
        return <Component {...props} navigate={navigate} />
    }
}

export default withRouter