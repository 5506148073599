import React, { useContext } from "react";
import { useState } from "react";
import {
  Form,
  Input,
  Select,
  Modal,
  Button,
  DatePicker,
  InputNumber,
  message,
} from "antd";

import InputGroup from "./InputGroup";
import withError from "hocs/withError";
import { FormModalPropType } from "util/types/props.types";
import { AppContextType } from "util/types/context.types";
import { appContext } from "contexts/AppContext";
import { addDevice as addDeviceApi } from "api/api";
import { DEVICE_TYPES } from "util/constants";

const AddDevice: React.FC<FormModalPropType> = ({ visible, closeForm }) => {
  const { setDevices } = useContext(appContext) as AppContextType;
  const [otherTypeVisible, setOtherTypeVisible] = useState<boolean>(false);
  const [formData] = Form.useForm();

  const addDevice = async () => {
    const response = await addDeviceApi(formData.getFieldsValue());
    if (response !== "error") {
      setDevices((prevDevices) => {
        if (response) {
          return [response, ...prevDevices];
        }

        return prevDevices;
      });
    }
  };

  const formDataChangeHandler = (value: any) => {
    formData.setFieldsValue(value);
  };

  const deviceTypeChangeHandler = (value: string) => {
    if (value === "other") {
      setOtherTypeVisible(true);
    } else {
      setOtherTypeVisible(false);
    }
  };

  const formSubmitHandler = () => {
    const errors = formData
      .getFieldsError()
      .filter((errorObj) => errorObj.errors.length > 0).length;
    if (errors === 0) {
      addDevice();
      formData.resetFields();
      closeForm();
      message.success("Device Added Succesfully");
    }
  };

  const formCancelHandler = () => {
    formData.resetFields();
    closeForm();
  };

  const renderNameInput = () => {
    return (
      <Form.Item name="name" label="Device Name" rules={[{ required: true }]}>
        <Input placeholder="Enter device name" />
      </Form.Item>
    );
  };

  const renderSNInput = () => {
    return (
      <Form.Item
        name="serial_number"
        label="Serial Number"
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter serial number" />
      </Form.Item>
    );
  };

  const renderTypeInput = () => {
    return (
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select
          options={DEVICE_TYPES}
          onChange={deviceTypeChangeHandler}
          placeholder="Select type"
        />
      </Form.Item>
    );
  };

  const renderExtraTypeInput = () => {
    return (
      <Form.Item
        name="other_type"
        label="Other Type"
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter type" />
      </Form.Item>
    );
  };

  const renderPriceInput = () => {
    return (
      <Form.Item name="price" label="Price" rules={[{ required: true }]}>
        <InputNumber
          placeholder="Enter price"
          min={0.01}
          step={0.01}
          style={{ width: "100%" }}
        />
      </Form.Item>
    );
  };

  const renderPurchaseDateInput = () => {
    return (
      <Form.Item
        name="purchase_date"
        label="Purchase Date"
        rules={[{ required: true }]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
    );
  };

  const renderOSInput = () => {
    return (
      <Form.Item name="os" label="Operating System">
        <Input placeholder="Enter operating system" style={{ width: "100%" }} />
      </Form.Item>
    );
  };

  const renderRAMInput = () => {
    return (
      <Form.Item name="ram" label="RAM value">
        <InputNumber
          placeholder="Enter RAM value"
          style={{ width: "100%" }}
          min={1}
        />
      </Form.Item>
    );
  };

  const renderCPUInput = () => {
    return (
      <Form.Item name="cpu" label="CPU">
        <Input placeholder="Enter CPU" />
      </Form.Item>
    );
  };

  const renderForm = () => {
    return (
      <Form
        form={formData}
        onValuesChange={formDataChangeHandler}
        onFinish={formSubmitHandler}
        layout="vertical"
      >
        <InputGroup>
          {renderNameInput()}
          {renderSNInput()}
        </InputGroup>
        {renderTypeInput()}
        {otherTypeVisible && renderExtraTypeInput()}
        {renderPurchaseDateInput()}
        {renderPriceInput()}
        <InputGroup>
          {renderCPUInput()}
          {renderRAMInput()}
        </InputGroup>
        {renderOSInput()}
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Add Device
        </Button>
      </Form>
    );
  };

  return (
    <Modal
      title="ADD DEVICE"
      visible={visible}
      onCancel={formCancelHandler}
      footer={[]}
    >
      {renderForm()}
    </Modal>
  );
};

export default withError(AddDevice);
