/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import Table, { ColumnType } from 'antd/lib/table'
import { Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import { DataIndex } from 'rc-table/lib/interface'

import { SearchTablePropType } from 'util/types/props.types'

const SearchTable: React.FC<SearchTablePropType> = ({ columns, dataSource, searchColumns }) => {
    const [, setSearchText] = useState<string>('')
    const [, setSearchedColumn] = useState<DataIndex>('')

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => { 
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
        setSearchText('')
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch((selectedKeys as string[]), confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch((selectedKeys as string[]), confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset((clearFilters as () => void))}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const property = Object(record)[dataIndex as string]
            return property.toString().toLowerCase().includes((value as string).toLowerCase())
        }
    })

    const searchableColumns: ColumnType<any>[] = columns.map(column => {
        if (searchColumns.includes(column.key as string)) {
            return { ...column, ...getColumnSearchProps(column.key as string) }
        } 
        return { ...column }
    }) as ColumnType<any>[]

    return (
        <Table 
            size='middle'
            columns={searchableColumns}
            dataSource={dataSource}
        />
    )
}

export default SearchTable