import React, { useEffect, useState } from 'react'

import Login from 'views/Login'
import googleService from 'services/GoogleService'

const LoginContainer: React.FC = () => { 
    const [loginUrl, setLoginUrl] = useState<string | null>(null)

    const getLoginUrl = async () => { 
        const response = await googleService.getLoginUrl()
        if (response !== 'error' ) {
            setLoginUrl(response)
        }
    }

    useEffect(() => {
        getLoginUrl()
    }, [])

    return <Login loginUrl={loginUrl}/>
}

export default LoginContainer