/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form, Modal, Button, Select, message } from "antd";

import withError from "hocs/withError";
import { FormModalPropType } from "util/types/props.types";
import { appContext } from "contexts/AppContext";
import { AppContextType } from "util/types/context.types";
import {
  assignDevice as assignDeviceApi,
  getUsers as getUsersApi,
} from "api/api";
import { useNavigate } from "react-router-dom";
import { DEVICES_PATH } from "util/paths";

const { Option } = Select;

const AssignDevice: React.FC<FormModalPropType> = ({ visible, closeForm }) => {
  const { users, selectedDevice, setUsers, setDevices } = useContext(
    appContext
  ) as AppContextType;
  const [serachedUser, setSearchedUser] = useState<string>("");
  const [formData] = Form.useForm();
  const navigate = useNavigate();

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(serachedUser.toLowerCase())
  );

  const assignDevice = async () => {
    if (selectedDevice) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const userId = users.find(
        (user) => user.name === formData.getFieldValue("owner")
      )!.id;
      await assignDeviceApi(userId, selectedDevice.id);
      setDevices((prevDevices) => {
        const pendingDevice = prevDevices.find(
          (device) => device.id === selectedDevice.id
        );
        const updatedDevices = prevDevices.filter(
          (device) => device.id !== selectedDevice.id
        );
        if (pendingDevice) {
          updatedDevices.unshift({
            ...pendingDevice,
            confirmation_pending: true,
          });
          return updatedDevices;
        }
        return prevDevices;
      });
      formData.resetFields();
      closeForm();
      navigate(DEVICES_PATH);
      message.info("Waiting For User Confirmation");
    }
  };

  const getUsers = useCallback(async () => {
    // Used for getting the users in the app
    const response = await getUsersApi();
    if (response !== "error") {
      setUsers(response);
    }
  }, [setUsers]);

  const formDataChangeHandler = (value: any) => {
    formData.setFieldsValue(value);
  };

  const formCancelHandler = () => {
    formData.resetFields();
    closeForm();
  };

  const formSubmitHandler = () => {
    const errors = formData
      .getFieldsError()
      .filter((errorObj) => errorObj.errors.length > 0).length;
    if (errors === 0) {
      assignDevice();
    }
  };

  const searchUserHandler = (value: string) => {
    setSearchedUser(value);
  };

  const renderSelectOptions = () =>
    filteredUsers.map((user) => (
      <Option key={user.id} value={user.name}>
        {user.name}
      </Option>
    ));

  const renderSelect = () => (
    <Form.Item name="owner" rules={[{ required: true }]} label="Search User">
      <Select
        showSearch
        showArrow={false}
        filterOption={false}
        onSearch={searchUserHandler}
        placeholder="Select a user"
      >
        {renderSelectOptions()}
      </Select>
    </Form.Item>
  );

  const renderForm = () => {
    return (
      <Form
        form={formData}
        onFinish={formSubmitHandler}
        onValuesChange={formDataChangeHandler}
        layout="vertical"
      >
        {renderSelect()}
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Assign Device
        </Button>
      </Form>
    );
  };

  useEffect(() => {
    if (users.length === 0) {
      // Trying to get users in the app, if they are not already present
      getUsers();
    }
  }, [users.length, getUsers]);

  return (
    <Modal
      title="ASSIGN DEVICE"
      visible={visible}
      onCancel={formCancelHandler}
      footer={[]}
    >
      {renderForm()}
    </Modal>
  );
};

export default withError(AssignDevice);
