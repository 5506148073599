import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

import { ChartWrapper } from 'styles/styles'
import { ChartPropTypes } from 'util/types/props.types'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart: React.FC<ChartPropTypes> = ({ width, height, options, data }) => {
    return (
        <ChartWrapper width={width} height={height}>
            <Pie options={options} data={data} />
        </ChartWrapper>
    )
}

export default PieChart