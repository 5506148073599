import React, { useCallback, useContext, useEffect, useState } from "react";
import { Timeline, Typography, Tag } from "antd";
import { RedoOutlined, CheckCircleOutlined } from "@ant-design/icons";

import withError from "hocs/withError";
import { appContext } from "contexts/AppContext";
import { AppContextType } from "util/types/context.types";
import { getDeviceHistory, getUsers as getUsersApi } from "api/api";
import { DeviceHistoryLog } from "util/types/generics.types";
import { DeviceHistoryRes } from "util/types/response.types";
import { FlexContainer } from "styles/styles";
import { HistoryPropType } from "util/types/props.types";

const DeviceHistory: React.FC<HistoryPropType> = ({ id }) => {
  const { users, setUsers } = useContext(appContext) as AppContextType;
  const [history, setHistory] = useState<DeviceHistoryLog[]>([]);

  const getUsers = useCallback(async () => {
    const response = await getUsersApi();
    if (response !== "error") {
      setUsers(users);
    }
  }, [users, setUsers]);

  const getAssignationLogs = useCallback(
    (history: DeviceHistoryRes[]): DeviceHistoryRes[] => {
      return history.filter((log) => log.old_user_id !== log.new_user_id);
    },
    []
  );

  const createDeviceHistory = useCallback(
    (logs: DeviceHistoryRes[]) => {
      return logs.map((log) => {
        let user_id: number | null;
        let status;
        if (log.new_user_id) {
          // device was assigned
          user_id = log.new_user_id;
          status = "ASSIGNED";
        } else {
          // device was returned
          user_id = log.old_user_id;
          status = "UNASSIGNED";
        }
        const userName = users.find((user) => user.id === user_id);
        return {
          id: log.id,
          device_id: log.device_id,
          owner: userName?.name as string,
          assigned_date: new Date(log.updatedAt).toLocaleDateString("en-US"),
          returned_date: log.new_returned_date
            ? new Date(log.updatedAt).toLocaleDateString("en-US")
            : null,
          status,
        };
      });
    },
    [users]
  );

  const getHistory = useCallback(async () => {
    const response = await getDeviceHistory(id);
    if (response !== "error") {
      const assignationLogs = getAssignationLogs(response);
      const history = createDeviceHistory(assignationLogs);
      setHistory(history);
    }
  }, [id, setHistory, getAssignationLogs, createDeviceHistory]);

  const renderAssignedTimelineItem = (log: DeviceHistoryLog) => (
    <Timeline.Item color="green" dot={<CheckCircleOutlined />} key={log.id}>
      <Typography.Text type="success" strong>
        (Assigned)
      </Typography.Text>
      {log.owner} at{" "}
      <Tag color="blue" style={{ borderRadius: "25px" }}>
        {log.assigned_date}
      </Tag>
    </Timeline.Item>
  );

  const renderUnassignedTimelineItem = (log: DeviceHistoryLog) => (
    <Timeline.Item color="red" dot={<RedoOutlined />} key={log.id}>
      <Typography.Text type="danger" strong>
        (Returned)
      </Typography.Text>
      {log.owner} at{" "}
      <Tag color="blue" style={{ borderRadius: "25px" }}>
        {log.returned_date}
      </Tag>
    </Timeline.Item>
  );

  const renderTimeline = () => (
    <Timeline mode="alternate">
      {history?.map((log: DeviceHistoryLog) => {
        if (log.status === "ASSIGNED") {
          return renderAssignedTimelineItem(log);
        }
        return renderUnassignedTimelineItem(log);
      })}
    </Timeline>
  );

  useEffect(() => {
    if (users.length === 0) {
      getUsers();
    } else {
      getHistory();
    }
  }, [users.length, getUsers, getHistory]);

  return (
    <>
      {history && history.length > 0 && (
        <FlexContainer justify="center" align="center" my="2em" mx="0">
          <Typography.Title level={2}>Device History</Typography.Title>
        </FlexContainer>
      )}
      {renderTimeline()}
    </>
  );
};

export default withError(DeviceHistory);
