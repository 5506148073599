/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import authService from 'services/AuthService'
import confirmationService from 'services/ConfirmationService'
import { LOGIN_PATH } from 'util/paths'

const withAuth = <P extends object>(Component: React.ComponentType<P>) => { 
    return (props: any) => { 
        const [redirectToLoginPage, setRedirectToLoginPage] = useState<boolean>(false)
        const [redirectToConfirmationPage, setRedirectToConfirmationPage] = useState<boolean>(false)
        const isConfirmationPending = confirmationService.isConfirmationPending()
        const isAuthenticated = authService.isAuthenticated()

        useEffect(() => {
            if (isConfirmationPending) {
                setRedirectToConfirmationPage(true)
                setRedirectToLoginPage(false)
            } else if (!isAuthenticated) {
                setRedirectToLoginPage(true)
                setRedirectToConfirmationPage(false)
            }
        }, [isConfirmationPending, isAuthenticated, setRedirectToConfirmationPage, setRedirectToLoginPage])

        if (redirectToConfirmationPage)  {  
            return <Navigate to={`${confirmationService.getConfirmationPath()}/${confirmationService.getConfirmationTokenFromStorage()}`} />
        }

        if (redirectToLoginPage) {
            return <Navigate to={LOGIN_PATH} />
        }

        return <Component {...props} />
    }
}

export default withAuth