import api from "./axios";
import moment from "moment";

import {
  DeivceType,
  Device,
  DeviceWithDepreciation,
  DeviceYear,
} from "util/types/generics.types";
import {
  GoogleUrlRes,
  UserStatusRes,
  UserRes,
  DeviceRes,
  UsersRes,
  MsgRes,
  DeviceHistoryRes,
  UserHistoryRes,
} from "util/types/response.types";

const convertToDevice = (deviceRes: DeviceRes): Device => {
  return {
    key: deviceRes.device_id ? deviceRes.device_id : deviceRes.id!,
    id: deviceRes.id!,
    device_id: deviceRes.device_id,
    name: deviceRes.name,
    type: deviceRes.type,
    other_type: deviceRes.other_type ?? "",
    price: deviceRes.price,
    owner: deviceRes.owner ? deviceRes.owner.name : "",
    os: deviceRes.specs.os ?? "",
    ram: deviceRes.specs.ram ?? "",
    cpu: deviceRes.specs.cpu ?? "",
    status: deviceRes.status!,
    serial_number: deviceRes.serial_number ?? "",
    user_id: deviceRes.user_id ?? null,
    is_broken: deviceRes.is_broken,
    is_duplicated_sn: deviceRes.is_duplicated_sn,
    confirmation_pending: deviceRes.confirmation_pending ? true : false,
    purchase_date: moment(deviceRes.purchase_date),
    return_date: deviceRes.return_date ? moment(deviceRes.return_date) : null,
  };
};

const createPayload = (device: Device): DeviceRes => {
  return {
    id: device.id ?? null,
    device_id: device.device_id ?? null,
    name: device.name,
    type: device.type,
    other_type: device.other_type ?? "",
    price: device.price,
    owner: { name: device.owner ?? "" },
    specs: {
      os: device.os ?? "",
      ram: device.ram ?? "",
      cpu: device.cpu ?? "",
    },
    serial_number: device.serial_number ?? "",
    user_id: device.user_id ?? null,
    is_broken: device.is_broken ? true : false,
    confirmation_pending: device.confirmation_pending ? true : false,
    purchase_date: device.purchase_date.toDate(),
    return_date: device.return_date ? device.return_date.toDate() : null,
  };
};

export const getGoogleUrl = async () => {
  try {
    const { data } = await api.get<GoogleUrlRes>("/google-url");
    return data.googleUrl;
  } catch (err) {
    return "error";
  }
};

export const getUserStatus = async () => {
  try {
    const { data } = await api.get<UserStatusRes>("/user-status", {
      withCredentials: true,
    });
    return {
      id: parseInt(data.user_id),
      role: data.user_type,
      email: data.user_mail,
      accessToken: data.access_token,
    };
  } catch (err) {
    return "error";
  }
};

export const logOutUser = async () => {
  try {
    await api.post("/logout", {}, { withCredentials: true });
  } catch (err) {
    return "error";
  }
};

export const getUserById = async (userId: number | string | undefined) => {
  try {
    const { data } = await api.get<UserRes>(`/user/${userId}`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    return "error";
  }
};

export const changeDeviceAvailability = async (
  availabilityStatus: boolean,
  deviceId: number
) => {
  try {
    await api.patch(
      `/change-device-availability/${deviceId}`,
      { is_broken: availabilityStatus },
      { withCredentials: true }
    );
  } catch (err) {
    return "error";
  }
};

export const getUserDevices = async (userId: number | string | undefined) => {
  try {
    const { data } = await api.get<DeviceRes[]>(`/get-devices/${userId}`, {
      withCredentials: true,
    });
    const devices = data.map((deviceRes) => convertToDevice(deviceRes));
    return devices;
  } catch (err) {
    return "error";
  }
};

export const getDeviceHistory = async (deviceId: number) => {
  try {
    const { data } = await api.get<DeviceHistoryRes[]>(
      `/device-logs/${deviceId}`,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    return "error";
  }
};

export const getUsers = async () => {
  try {
    const { data } = await api.get<UsersRes>("/users", {
      withCredentials: true,
    });
    return data.users;
  } catch (err) {
    return "error";
  }
};

export const getUserHistory = async (userId: number) => {
  try {
    const { data } = await api.get<UserHistoryRes[]>(`/user-logs/${userId}`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    return "error";
  }
};

export const syncUsers = async (accessToken: string) => {
  try {
    const { data } = await api.post<UsersRes | MsgRes>(
      `/sync-users/${accessToken}`,
      {},
      { withCredentials: true }
    );
    if ("users" in data) {
      return data.users;
    } else {
      return data.message;
    }
  } catch (err) {
    return "error";
  }
};

export const changeUserRole = async (userId: number, role: string) => {
  try {
    const { data } = await api.patch<UserRes>(
      `/change-role/${userId}`,
      { role },
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    return "error";
  }
};

export const getDevices = async () => {
  try {
    const { data } = await api.get<DeviceRes[]>("/get-all-devices", {
      withCredentials: true,
    });
    const devices = data.map((deviceRes) => convertToDevice(deviceRes));
    return devices;
  } catch (err) {
    return "error";
  }
};

export const getDeviceById = async (deviceId: number | string) => {
  try {
    const { data } = await api.get<DeviceRes>(`/get-device/${deviceId}`, {
      withCredentials: true,
    });
    const device = convertToDevice(data);
    return device;
  } catch (err) {
    return "error";
  }
};

export const addDevice = async (device: Device) => {
  try {
    const { data } = await api.post<DeviceRes>(
      "/add-device",
      createPayload(device),
      { withCredentials: true }
    );
    const insertedDevice = convertToDevice(data);
    return insertedDevice;
  } catch (err) {
    return "error";
  }
};

export const editDevice = async (deviceId: number, deviceData: any) => {
  try {
    const { data } = await api.put<DeviceRes>(
      `/change-device-details/${deviceId}`,
      createPayload(deviceData),
      { withCredentials: true }
    );
    const device = convertToDevice(data);
    return device;
  } catch (err) {
    return "error";
  }
};

export const deleteDevice = async (deviceId: number) => {
  try {
    await api.delete(`/delete-device/${deviceId}`, { withCredentials: true });
  } catch (err) {
    return "error";
  }
};

export const returnDevice = async (
  userId: number,
  deviceId: number,
  returnDate: Date
) => {
  try {
    await api.post(`/return-device/${userId}/${deviceId}`, returnDate, {
      withCredentials: true,
    });
    return null;
  } catch (err) {
    return "error";
  }
};

export const assignDevice = async (userId: number, deviceId: number) => {
  try {
    const response = await api.patch(
      `/assign-device/${userId}/${deviceId}`,
      {},
      { withCredentials: true }
    );
    return response;
  } catch (err) {
    console.log(err);
    return "error";
  }
};

export const confirmDeviceReceived = async (confirmationToken: string) => {
  try {
    const response = await api.post(
      `/confirm-device/${confirmationToken}`,
      {},
      { withCredentials: true }
    );
    return response;
  } catch (err) {
    return "error";
  }
};

export const confirmDeviceReturned = async (confirmationToken: string) => {
  try {
    await api.post(
      `/confirm-return-device/${confirmationToken}`,
      {},
      { withCredentials: true }
    );
  } catch (err) {
    return "error";
  }
};

export const uploadDevices = async (body: any) => {
  try {
    const { data } = await api.post<DeviceRes[]>(
      "/upload-devices",
      { file: body.file },
      {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    const devices = data.map((deviceRes: DeviceRes) =>
      convertToDevice(deviceRes)
    );
    return devices;
  } catch (err) {
    return "error";
  }
};

export const getDevicesDepreciation = async () => {
  try {
    const { data } = await api.get<DeviceWithDepreciation[]>(
      "/device-reports",
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    return "error";
  }
};

export const getTotalDeviceCount = async () => {
  try {
    const { data } = await api.get<number>("/total-device-count", {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    return "error";
  }
};

export const getTotalAvailableDeviceCount = async () => {
  try {
    const { data } = await api.get<number>("/total-available-devices", {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    return "error";
  }
};

export const getDeviceTypes = async () => {
  try {
    const { data } = await api.get<DeivceType[]>("/device-types", {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    return "error";
  }
};

export const getDeviceAges = async () => {
  try {
    const { data } = await api.get<DeviceYear[]>("/device-ages", {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    return "error";
  }
};

export const logout = async () => {
  try {
    await api.post("/logout", {}, { withCredentials: true });
  } catch (err) {
    return "error";
  }
};
