import React, { useCallback, useContext, useEffect } from 'react'

import AppRoutes from 'AppRoutes'
import authService from 'services/AuthService'
import { authContext } from 'contexts/AuthContext'
import { AuthContextType } from 'util/types/context.types'
import { ActiveUser } from 'util/types/generics.types'

const App: React.FC = () => {
    const { setActiveUser } = useContext(authContext) as AuthContextType

    const login = useCallback(async () => { 
        const response = await authService.authenticate()
        if (response !== 'error') {
            setActiveUser(response)
        }
    }, [setActiveUser])

    useEffect(() => {
        if (!authService.isAuthenticated()) {
            login() 
        } else { 
            setActiveUser(authService.getUser() as ActiveUser)
        }
    }, [login, setActiveUser])

    return <AppRoutes />
}

export default App