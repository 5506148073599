import { Button, Modal, Typography } from "antd";
import { appContext } from "contexts/AppContext";
import React, { useContext } from "react";
import { AppContextType } from "util/types/context.types";
import { FormModalPropType } from "util/types/props.types";
import { deleteDevice as deleteDeviceApi } from "api/api";
import { message } from "antd";

const DeleteDevice: React.FC<FormModalPropType> = ({ closeForm, visible }) => {
  const { selectedDevice, setDevices } = useContext(
    appContext
  ) as AppContextType;

  const { Text } = Typography;

  const deleteDevice = async (deviceId: number) => {
    const response = await deleteDeviceApi(deviceId);
    if (response !== "error") {
      setDevices((prevDevices) =>
        prevDevices.filter((device) => device.id !== deviceId)
      );
      message.success("Device Deleted Succesfully");
    }
    closeForm();
  };

  return (
    <Modal
      title="DELETE DEVICE"
      visible={visible}
      onCancel={() => closeForm()}
      footer={[
        <Button
          onClick={() => deleteDevice(selectedDevice!.id)}
          key="submit"
          type="primary"
        >
          Yes
        </Button>,
        <Button key="back" onClick={() => closeForm()}>
          No
        </Button>,
      ]}
    >
      <Text>
        Are you sure you want to delete
        <Text style={{ color: "blue" }}> {selectedDevice?.name}</Text> from
        Inventory Management ?
      </Text>
    </Modal>
  );
};

export default DeleteDevice;
