/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import authService from 'services/AuthService'
import NeedsAuthenticationDialog from 'components/confirmations/NeedsAuthenticationDialog'

const withConfirmation = <P extends object>(Component: React.ComponentType<P>) => { 
    return (props: any) => { 
        const [needAuthentication, setNeedsAuthentication] = useState<boolean>(false)
        const [confirmationPath, setConfirmationPath] = useState<string>('')
        const location = useLocation()
        const isAuthenticated = authService.isAuthenticated()

        const getConfirmationPathFromPathname = useCallback(() => { 
            return '/' + location.pathname.split('/')[1]
        }, [location.pathname])

        useEffect(() => {
            if (!isAuthenticated) {
                setNeedsAuthentication(true)
                setConfirmationPath(getConfirmationPathFromPathname())
            } else { 
                setNeedsAuthentication(false)
            }
        }, [isAuthenticated, setNeedsAuthentication, setConfirmationPath, getConfirmationPathFromPathname])

        if (needAuthentication) { 
            return <NeedsAuthenticationDialog confirmationPath={confirmationPath} />
        }

        return <Component {...props} />
    }
}

export default withConfirmation