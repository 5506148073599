/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import LoginContainer from 'containers/LoginContainer'
import ProfileContainer from 'containers/ProfileContainer'
import UsersContainer from 'containers/UsersContainer.'
import UserContainer from 'containers/UserContainer'
import DevicesContainer from 'containers/DevicesContainer'
import DeviceContainer from 'containers/DeviceContainer'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import Confirmation from 'components/confirmations/Confirmation'
import ReportsContainer from 'containers/ReportsContainer'
import authService from 'services/AuthService'
import { CONFIRM_DEVICE_RECEIVED_PATH, CONFIRM_DEVICE_RETURNED_PATH, DEVICES_PATH, LOGIN_PATH, REPORTS_PATH, ROOT_PATH, USERS_PATH } from 'util/paths'
import { CONFIRMATION_TYPES, USER_ROLES } from 'util/constants'
import { RotuerPropExt } from 'util/types/props.types'


const pathsConfig: RotuerPropExt[] = [
    { path: LOGIN_PATH, element: <LoginContainer /> },
    { path: ROOT_PATH, element: <ProfileContainer />, allow: [USER_ROLES.ADMIN, USER_ROLES.USER] },
    { path: USERS_PATH, element: <UsersContainer />, allow: [USER_ROLES.ADMIN] },
    { path: USERS_PATH + '/:userId', element: <UserContainer />, allow: [USER_ROLES.ADMIN] },
    { path: DEVICES_PATH, element: <DevicesContainer />, allow: [USER_ROLES.ADMIN] },
    { path: DEVICES_PATH + '/:deviceId', element: <DeviceContainer />, allow: [USER_ROLES.ADMIN, USER_ROLES.USER] },
    { path: REPORTS_PATH, element: <ReportsContainer />, allow: [USER_ROLES.ADMIN] },
    { path: CONFIRM_DEVICE_RECEIVED_PATH + '/:token', element: <Confirmation confirmationType={CONFIRMATION_TYPES.RECEIVED}/>, allow: [USER_ROLES.ADMIN, USER_ROLES.USER] },
    { path: CONFIRM_DEVICE_RETURNED_PATH + '/:token', element: <Confirmation confirmationType={CONFIRMATION_TYPES.RETURNED} />, allow: [USER_ROLES.ADMIN, USER_ROLES.USER] },
]

const hasAccess = (routeProps: RotuerPropExt) => { 
    const role = authService.getUserRole() as USER_ROLES

    if (!role) { 
        return true
    }

    if (routeProps.allow) {
        return routeProps.allow.includes(role)
    }

    return false
}

export const hasAccessToPath = (link: {path: string, name: string}) => { 
    const role = authService.getUserRole() as USER_ROLES
    const route = pathsConfig.find(p => p.path === link.path) as RotuerPropExt
    
    if (route.allow && role) {
        return route.allow.includes(role)
    }

    return false
}

const AppRoutes = () => {    
    return (
        <Routes>
            <Route element={<ErrorBoundary />}>
                {
                    pathsConfig.map((path, i) => {
                        return hasAccess(path)? <Route path={path.path} element={path.element} key={i} /> : ''
                    })
                }
                <Route path='*' element={<Navigate to={ROOT_PATH} />} />
            </Route>
        </Routes>

    )
}

export default AppRoutes