/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserStatus, logout } from 'api/api'
import { ActiveUser } from 'util/types/generics.types'

const USER = 'user'
const TOKEN_EXPIRATION_TIME = 60 * 60 * 1000

class TokenService {
    tokenTimer: any = null

    private stopTokenExpirationTimer() {
        this.tokenTimer = clearInterval(this.tokenTimer)
    }

    private startTokenExpirationTimer() {
        if (this.tokenTimer !== undefined) {
            this.stopTokenExpirationTimer()
        }
        this.tokenTimer = setInterval(this.clearAll, TOKEN_EXPIRATION_TIME)
    }

    private setTokenData(data: ActiveUser) {
        localStorage.setItem(USER, JSON.stringify(data))
    }

    getAccessToken(): ActiveUser { 
        return JSON.parse(localStorage.getItem(USER) as string)
    }

    // clears cookies throug logout() and local storage
    async clearAll() {
        await logout()
        localStorage.clear()
    }

    async retrieveTokenData() { 
        const user = await getUserStatus()
        if (user !== 'error') {
            this.setTokenData(user)
            this.startTokenExpirationTimer()
        }
        return user
    }
}

export default new TokenService()