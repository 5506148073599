import tokenService from 'services/TokenService'

class AuthService { 
    authenticate() { 
        return tokenService.retrieveTokenData()
    }

    async logout() { 
        await tokenService.clearAll()
    }

    getUserRole() { 
        const tokenData = tokenService.getAccessToken()
        if (tokenData) {
            return tokenData.role
        }

        return null
    }

    getUser() { 
        const tokenData = tokenService.getAccessToken()
        if (tokenData) { 
            return tokenData
        }
    }

    isAuthenticated() { 
        return !!tokenService.getAccessToken()
    }
}

export default new AuthService()