import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import Profile from 'views/Profile'
import withError from 'hocs/withError'
import withAuth from 'hocs/withAuth'
import { authContext } from 'contexts/AuthContext'
import { Device, User } from 'util/types/generics.types'
import { getUserById, getUserDevices } from 'api/api'
import { CustomSpin } from 'styles/styles'
import { AuthContextType } from 'util/types/context.types'
import { DEVICES_PATH } from 'util/paths'

const ProfileContainer: React.FC = () => { 
    const [user, setUser] = useState<User | null>(null)
    const [userDevices, setUserDevices] = useState<Device[] | []>([])
    const { activeUser } = useContext(authContext) as AuthContextType
    const navigate = useNavigate()

    const getUser = useCallback(async () => { 
        if (activeUser) {
            const response = await getUserById(activeUser.id)
            if (response !== 'error') {
                setUser(response)
            }
        }
    }, [activeUser, setUser])

    const getDevices = useCallback(async () => { 
        if (activeUser) { 
            const response = await getUserDevices(activeUser.id)
            if (response !== 'error') { 
                setUserDevices(response)
            }
        }
    }, [activeUser, setUserDevices])

    const selectDeviceHandler = async (deviceId: number) => { 
        navigate(`${DEVICES_PATH}/${deviceId}`)
    }

    useEffect(() => {
        getUser()
        getDevices()
    }, [activeUser, getUser, getDevices])

    return (
        !user ? 
            <CustomSpin size='large' /> : 
            (
                <Profile 
                    user={user}
                    devices={userDevices}
                    ownProfile={true}
                    onRoleChange={() => null}
                    onDeviceSelected={selectDeviceHandler}
                />
            )
    )
}

export default withError(withAuth(ProfileContainer))