import React from 'react'
import { useEffect, useState } from 'react'

import BarChart from './common/BarChart'
import { CustomSpin } from '../../styles/styles'
import { getDeviceAges } from 'api/api'
import { DeviceYear } from 'util/types/generics.types'

const DeviceYearsChart = () => {
    const [deviceYears, setDeviceYears] = useState<DeviceYear[]>([])
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    }
    const labels = deviceYears.map(deviceeType => deviceeType.year)
    const data = {
        labels,
        datasets: [
            {
                label: '# of devices from specific year',
                data: deviceYears.map(deviceTypes => deviceTypes.count),
                backgroundColor: 'rgba(53, 162, 235, 0.5',
                borderColor: 'rgb(53, 162, 235)',
                borderWidth: 2
            }
        ]

    }

    const getDeviceYearsStatistics = async () => {
        const response = await getDeviceAges()
        if (response !== 'error') { 
            setDeviceYears(response)
        }
    }

    const renderChart = () => {
        return (
            <BarChart width='100%' options={options} data={data} />
        )
    }

    useEffect(() => {
        getDeviceYearsStatistics()
    }, [])

    return deviceYears.length > 0 ? renderChart() : <CustomSpin size='large' />
}

export default DeviceYearsChart