import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, message } from "antd";

import Device from "views/Device";
import withError from "hocs/withError";
import withAuth from "hocs/withAuth";
import { appContext } from "contexts/AppContext";
import { AppContextType, AuthContextType } from "util/types/context.types";
import { authContext } from "contexts/AuthContext";
import { Device as DeviceType } from "util/types/generics.types";
import { CustomSpin } from "styles/styles";
import {
  editDevice,
  getDeviceById,
  deleteDevice,
  changeDeviceAvailability,
} from "api/api";
import { DEVICES_PATH } from "util/paths";

const DeviceContainer: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [device, setDevice] = useState<DeviceType | null>(null);
  const [deviceType, setDeviceType] = useState<string>("");
  const [formData] = Form.useForm();
  const { formVisibility, showAssignDeviceForm, showReturnDeviceForm } =
    useContext(appContext) as AppContextType;
  const { deviceId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { activeUser } = useContext(authContext) as AuthContextType;
  const navigate = useNavigate();

  const getDevice = useCallback(async () => {
    const response = await getDeviceById(deviceId as string);
    if (response !== "error") {
      setDevice(response);
      setDeviceType(response.type);
    }
  }, [deviceId, setDevice, setDeviceType]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formDataChangeHandler = (value: any) => {
    formData.setFieldsValue(value);
  };

  // save device after edit handler
  const formSubmitHandler = async () => {
    if (device) {
      // need to provide in the body of the api call also the readonly fields
      const response = await editDevice(device.id, {
        ...formData.getFieldsValue(),
        status: device.status,
        owner: device.owner,
        user_id: device.user_id,
        return_date: device.return_date,
      });
      if (response !== "error") {
        navigate(DEVICES_PATH);
        message.success(`${response.name} Details Saved`);
      }
    }
  };

  const deleteDeviceHandler = async () => {
    if (device) {
      await deleteDevice(device.id);
      navigate(DEVICES_PATH);
      message.success(`${device.name} Deleted Succesfully`);
    }
  };

  const deviceAvailabilityChangeHandler = async () => {
    if (device) {
      await changeDeviceAvailability(!device.is_broken, device.id);
      navigate(DEVICES_PATH);
      message.success(
        `${device.name} Marked ${!device.is_broken ? "BROKEN" : "WORKING"}`
      );
    }
  };

  useEffect(() => {
    getDevice();
  }, [getDevice]);

  return device === null || activeUser === null ? (
    <CustomSpin size="large" />
  ) : (
    <Device
      userRole={activeUser.role}
      device={device}
      deviceType={deviceType}
      formData={formData}
      formVisibility={formVisibility}
      showAssignDeviceForm={showAssignDeviceForm}
      showReturnDeviceForm={showReturnDeviceForm}
      onChange={formDataChangeHandler}
      onDeviceTypeChange={setDeviceType}
      onFinish={formSubmitHandler}
      onDeleteDevice={deleteDeviceHandler}
      onDeviceAvailabilityChange={deviceAvailabilityChangeHandler}
    />
  );
};

export default withError(withAuth(DeviceContainer));
