import React from "react";
import moment from "moment";
import { Tag, Row, Col, Typography, Button, Upload } from "antd";
import {
  PlusCircleOutlined,
  ExclamationCircleFilled,
  CloudDownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import SearchTable from "components/common/SearchTable";
import AddDevice from "components/forms/AddDevice";
import ReturnDevice from "components/forms/ReturnDevice";
import AssignDevice from "components/forms/AssignDevice";
import withLayout from "hocs/withLayout";
import { DevicesPropType } from "util/types/props.types";
import { Device } from "util/types/generics.types";
import {
  AvailabilityStatus,
  CustomSpin,
  DefaultVSPButton,
} from "styles/styles";
import { DEVICE_STATES, DEVICE_TYPES } from "util/constants";
import DeleteDevice from "./DeleteDevice";

const Devices: React.FC<DevicesPropType> = ({
  devices,
  uploadingDevices,
  formVisibility,
  showAddDeviceForm,
  showAssignDeviceForm,
  showReturnDeviceForm,
  showDeleteDeviceForm,
  onDeleteDevice,
  onUploadDevices,
  onOpenDevice,
}) => {
  const renderDeviceName = (text: string, record: Device) => {
    return (
      <AvailabilityStatus
        available={!record.is_broken}
        emphasise={record.is_broken}
      >
        {text}
      </AvailabilityStatus>
    );
  };

  const renderSerialNumber = (text: string, record: Device) =>
    record.is_duplicated_sn ? (
      <Typography.Text type="danger">{text}</Typography.Text>
    ) : (
      <Typography.Text>{text}</Typography.Text>
    );

  const renderStateTags = (_: string, record: Device) => {
    const deviceState = DEVICE_STATES.find(
      (deviceState) => deviceState.value === record.status
    );
    return (
      <Tag
        color={deviceState?.color}
        key={`${record.device_id}-${deviceState?.color}`}
        style={{ borderRadius: "25px" }}
      >
        {record.status}
      </Tag>
    );
  };

  const renderPurchaseDate = (date: moment.Moment) => {
    return date.format("YYYY-MM-DD");
  };

  const renderOwner = (text: string, record: Device) => {
    if (record.confirmation_pending) {
      return (
        <Row gutter={8}>
          <Col>
            <Typography.Text type="danger">Pending</Typography.Text>
          </Col>
          <Col>
            <ExclamationCircleFilled style={{ color: "red" }} />
          </Col>
        </Row>
      );
    }

    return text ? text : "-";
  };

  const renderRecordActions = (record: Device) => {
    return (
      <div key={`actions-for-${record.serial_number}`}>
        <DefaultVSPButton
          type="primary"
          style={{ marginRight: "0.8em", borderRadius: "25px" }}
          onClick={() => onOpenDevice(record.id)}
        >
          Open
        </DefaultVSPButton>
        <Button
          type="primary"
          style={{ marginRight: "0.8em", borderRadius: "25px" }}
          onClick={() => showDeleteDeviceForm(record)}
          danger
        >
          Delete
        </Button>
        {record.owner || record.confirmation_pending ? (
          <Button
            type="dashed"
            style={{ marginRight: "0.8em", borderRadius: "25px" }}
            onClick={() => showReturnDeviceForm(record)}
          >
            Return
          </Button>
        ) : (
          <Button
            type="default"
            style={{ marginRight: "0.8em", borderRadius: "25px" }}
            onClick={() => showAssignDeviceForm(record)}
            disabled={record.confirmation_pending}
          >
            Assign
          </Button>
        )}
      </div>
    );
  };

  const renderHeading = () => (
    <Row>
      <Col style={{ marginRight: "1em" }}>
        <Typography.Title level={2}>REGISTERED DEVICES</Typography.Title>
      </Col>
      <Col style={{ marginRight: "1em" }}>
        <DefaultVSPButton
          size="large"
          icon={<PlusCircleOutlined />}
          onClick={showAddDeviceForm}
        >
          Add device
        </DefaultVSPButton>
      </Col>
      <Col style={{ marginRight: "1em" }}>
        <DefaultVSPButton
          htmlType="submit"
          size="large"
          icon={<CloudDownloadOutlined />}
          href="/export-devices"
        >
          Export Devices
        </DefaultVSPButton>
      </Col>
      <Col style={{ marginRight: "1em" }}>
        <Upload
          type="select"
          customRequest={(data) => onUploadDevices(data)}
          name="file"
          accept=".csv"
          showUploadList={false}
          withCredentials
        >
          <DefaultVSPButton size="large" icon={<UploadOutlined />}>
            Upload Devices
          </DefaultVSPButton>
        </Upload>
      </Col>
    </Row>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Device) => renderDeviceName(text, record),
    },

    {
      title: "SN",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text: string, record: Device) =>
        renderSerialNumber(text, record),
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: DEVICE_TYPES.map((deviceType) => ({
        text: deviceType.value,
        value: deviceType.value,
      })),
      onFilter: (value: string | number | boolean, record: Device) =>
        record.type.indexOf(value as string) === 0,
    },

    {
      title: "State",
      dataIndex: "status",
      key: "status",
      filters: DEVICE_STATES.map((deviceState) => ({
        text: deviceState.value,
        value: deviceState.value,
      })),
      onFilter: (value: string | number | boolean, record: Device) =>
        record.status.indexOf(value as string) === 0,
      render: (text: string, record: Device) => renderStateTags(text, record),
    },

    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      key: "purchase_date",
      render: (date: moment.Moment) => renderPurchaseDate(date),
    },

    {
      title: "Assigned To",
      dataIndex: "owner",
      key: "owner",
      filters: [{ text: "pending", value: "Pending" }],
      render: (text: string, device: Device) => renderOwner(text, device),
      onFilter: (_: string | number | boolean, record: Device) =>
        record.confirmation_pending,
    },

    {
      title: "Actions",
      key: "action",
      render: (record: Device) => renderRecordActions(record),
    },
  ];

  return (
    <>
      {renderHeading()}
      {uploadingDevices ? (
        <CustomSpin size="large" />
      ) : (
        <>
          <SearchTable
            columns={columns}
            dataSource={devices}
            searchColumns={["name", "serial_number"]}
          />
          <AddDevice
            visible={formVisibility.add}
            closeForm={showAddDeviceForm}
          />
          <AssignDevice
            visible={formVisibility.assign}
            closeForm={showAssignDeviceForm}
          />
          <ReturnDevice
            visible={formVisibility.return}
            closeForm={showReturnDeviceForm}
          />
          <DeleteDevice
            visible={formVisibility.delete}
            closeForm={showDeleteDeviceForm}
          />
        </>
      )}
    </>
  );
};

export default withLayout(Devices);
