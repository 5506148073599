import React, { useEffect, useState } from 'react'

import PieChart from './common/PieChart'
import { CustomSpin } from 'styles/styles'
import { DEVICE_TYPES } from 'util/constants'
import { getDeviceTypes } from 'api/api'
import { DeivceType } from 'util/types/generics.types'

const DeviceTypesChart = () => {
    const [deviceTypes, setDeviceTypes] = useState<DeivceType[]>([])
    const labels = deviceTypes.map(deviceeType => deviceeType.type)
    const options = {
        responsive: true,
    }
    const data = {
        labels,
        datasets: [
            {
                label: '# of devices from specific year',
                data: deviceTypes.map(deviceTypes => deviceTypes.count),
                backgroundColor: DEVICE_TYPES.map(appDeviceType => appDeviceType.pieColor),
                borderColor: DEVICE_TYPES.map(appDeviceTypes => appDeviceTypes.pieBorderColor),
                borderWidth: 1,
            }
        ]

    }

    const getDeviceTypesStatistics = async () => {
        const response = await getDeviceTypes()
        if (response !== 'error') { 
            setDeviceTypes(response.filter(deviceType => deviceType.type !== null))
        }
    }

    const renderChart = () => {
        return (
            <PieChart data={data} options={options} />
        )
    }

    useEffect(() => {
        getDeviceTypesStatistics()
    }, [])

    return deviceTypes.length > 0 ? renderChart() : <CustomSpin size='large' />
}

export default DeviceTypesChart