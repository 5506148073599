import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Users from 'views/Users'
import withError from 'hocs/withError'
import withAuth from 'hocs/withAuth'
import { User } from 'util/types/generics.types'
import { getUsers as getUsersApi, syncUsers } from 'api/api'
import { authContext } from 'contexts/AuthContext'
import { AppContextType, AuthContextType } from 'util/types/context.types'
import { message } from 'antd'
import { CustomSpin } from 'styles/styles'
import { appContext } from 'contexts/AppContext'
import { USERS_PATH } from 'util/paths'

const UsersContainer: React.FC = () => { 
    const {users, setUsers} = useContext(appContext) as AppContextType
    const [synchingUsers, setSynchingUsers] = useState<boolean>(false)
    const [searchedUser, setSearchedUsers] = useState<string>('')
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { activeUser } = (useContext(authContext) as AuthContextType)
    const filteredUsers = users.filter((user: User) => user.name.toLowerCase().includes(searchedUser.toLowerCase()))
    const navigate = useNavigate()

    const getUsers = useCallback(async () => { 
        const response = await getUsersApi()
        if (response !== 'error') {
            setUsers(response)
        }
    }, [setUsers])

    const searchUserHandler = (e: ChangeEvent<HTMLInputElement>) => { 
        setSearchedUsers(e.target.value)
    }

    const selectUserHandler = (user: User) => { 
        navigate(`${USERS_PATH}/${user.id}`)
    }

    const syncUsersHandler = async () => {
        if (!activeUser) return;
    
        setSynchingUsers(true);
    
        try {
            const response = await syncUsers(activeUser.accessToken);
            if (typeof response === 'string') {
                message.error('Error occurred during user sync');
            } else if (response.length > 0) {
                setUsers((prevUsers) => [...prevUsers, ...response]);
                message.success(`${response.length} New Users Added`);
            } else {
                message.info('No New Users Detected');
            }
        } catch (error) {
            message.error('Error occurred during sync.');
        } finally {
            setSynchingUsers(false);
        }
    };

    useEffect(() => {
        getUsers()
    }, [getUsers])

    return ( 
        users.length === 0? 
            <CustomSpin size='large' /> : 
            (
                <Users 
                    users={filteredUsers}
                    synchingUsers={synchingUsers}
                    onSearchUser={searchUserHandler}
                    onSyncUsers={syncUsersHandler}
                    onUserSelected={selectUserHandler}    
                />
            )  
    )
}

export default withError(withAuth(UsersContainer))