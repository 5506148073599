import React from 'react'
import { Result } from 'antd'

import { FlexContainer } from 'styles/styles'
import { ConfirmationDialogPropType } from 'util/types/props.types'

const ConfirmationDialog: React.FC<ConfirmationDialogPropType> = ({ status, title, extra }) => { 
    return (
        <FlexContainer
            justify='center'
            align='center'
            height='70vh'
        >
            <Result 
                status={status}
                title={title}
                extra={extra}
            />
        </FlexContainer>
    )   
}

export default ConfirmationDialog