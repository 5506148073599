import React, { useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import ConfirmationDialog from "./ConfirmationDialog";
import confirmationService from "services/ConfirmationService";
import withConfirmation from "hocs/withConfirmation";
import authService from "services/AuthService";
import { DEFAULT_PATH_AFTER_LOGIN } from "util/paths";
import { ConfirmationPropType } from "util/types/props.types";

const Confirmation: React.FC<ConfirmationPropType> = ({ confirmationType }) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const isAuthenticated = authService.isAuthenticated();
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<any>("success");

  const confirmAction = useCallback(async () => {
    const data: any = await confirmationService.confirmAction(
      confirmationType,
      token as string
    );

    setStatus(data.data.type);
    setMessage(data.data.msg);
  }, [confirmationType, token]);

  useEffect(() => {
    if (isAuthenticated) {
      confirmAction();
    }
  }, [isAuthenticated, confirmAction]);

  return (
    <ConfirmationDialog
      status={status}
      title={message}
      extra={[
        <Button
          size="large"
          type="primary"
          key={1}
          onClick={() => navigate(DEFAULT_PATH_AFTER_LOGIN)}
          style={{ borderRadius: "25px" }}
        >
          GO TO APP
        </Button>,
      ]}
    />
  );
};

export default withConfirmation(Confirmation);
