/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Button, DatePicker, message } from "antd";

import withError from "hocs/withError";
import { FormModalPropType } from "util/types/props.types";
import { appContext } from "contexts/AppContext";
import { AppContextType } from "util/types/context.types";
import { returnDevice as returnDeviceApi } from "api/api";
import { DEVICES_PATH } from "util/paths";

const ReturnDevice: React.FC<FormModalPropType> = ({ visible, closeForm }) => {
  const { selectedDevice, devices, setDevices } = useContext(
    appContext
  ) as AppContextType;
  const [formData] = Form.useForm();
  const navigate = useNavigate();

  const returnDevice = async () => {
    if (selectedDevice) {
      const response = await returnDeviceApi(
        selectedDevice.user_id,
        selectedDevice.id,
        formData.getFieldsValue()
      );
      if (response !== "error") {
        setDevices((prevDevices) => {
          const pendingDevice = devices.find(
            (device) => device.id === selectedDevice.id
          );
          let updatedDevices = devices.filter(
            (device) => device.id !== selectedDevice.id
          );
          if (pendingDevice) {
            updatedDevices = [
              {
                ...pendingDevice,
                status: "unassigned",
                owner: "",
                confirmation_pending: false,
              },
              ...updatedDevices,
            ];

            return updatedDevices;
          }
          return prevDevices;
        });
        formData.resetFields();
        closeForm();
        navigate(DEVICES_PATH);
        message.success("Device succesfully returned !");
      }
    }
  };

  const formDataChangeHandler = (value: any) => {
    formData.setFieldsValue(value);
  };

  const formSubmitHandler = () => {
    const errors = formData
      .getFieldsError()
      .filter((errorObj) => errorObj.errors.length > 0).length;
    if (errors === 0) {
      returnDevice();
    }
  };

  const formCancelHandler = () => {
    formData.resetFields();
    closeForm();
  };

  const renderReturnDateInput = () => {
    return (
      <Form.Item
        name="return_date"
        label="Return Date"
        rules={[{ required: true }]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
    );
  };

  const renderForm = () => {
    return (
      <Form
        form={formData}
        onValuesChange={formDataChangeHandler}
        onFinish={formSubmitHandler}
        layout="vertical"
      >
        {renderReturnDateInput()}
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Return Device
        </Button>
      </Form>
    );
  };

  return (
    <Modal
      title="RETURN DEVICE"
      visible={visible}
      onCancel={formCancelHandler}
      footer={[]}
    >
      {renderForm()}
    </Modal>
  );
};

export default withError(ReturnDevice);
