import React, { useContext } from "react";
import { Avatar, Button, Card, Tag, Typography } from "antd";
import { UserOutlined, ToolOutlined } from "@ant-design/icons";

import Meta from "antd/lib/card/Meta";
import UserHistory from "components/histories/UserHistory";
import withLayout from "hocs/withLayout";
import { DeviceLabel, FlexContainer, GridContainer } from "styles/styles";
import { ProfilePropType } from "util/types/props.types";
import { Device } from "util/types/generics.types";
import { DEVICE_TYPES } from "util/constants";
import { authContext } from "contexts/AuthContext";
import { AuthContextType } from "util/types/context.types";

const Profile: React.FC<ProfilePropType> = ({
  user,
  devices,
  ownProfile,
  onRoleChange,
  onDeviceSelected,
}) => {
  const { activeUser } = useContext(authContext) as AuthContextType;

  const names = user?.name.toUpperCase().split(" ");

  const renderUserInfo = () => (
    <FlexContainer direction="row" align="center" justify="center" colGap="1em">
      <Avatar size={120}>
        {names ? names[0][0] : ""} {names ? names[1][0] : ""}
      </Avatar>
      <FlexContainer direction="column" justify="center" align="center">
        <Typography.Title level={1}>{user?.name}</Typography.Title>
        <Typography.Paragraph>{user?.email}</Typography.Paragraph>
        <Tag
          color={user?.role === "admin" ? "green" : "red"}
          icon={user?.role === "admin" ? <ToolOutlined /> : <UserOutlined />}
          style={{ borderRadius: "25px" }}
        >
          {user?.role}
        </Tag>
      </FlexContainer>
    </FlexContainer>
  );

  const renderChangeRoleButton = () => {
    return (
      <>
        {user?.id === activeUser?.id ? null : (
          <Button
            style={{ width: "100%" }}
            danger={user?.role === "admin" ? true : false}
            onClick={() => onRoleChange()}
          >
            {user?.role === "admin" ? "Revoke Admin" : "Make Admin"}
          </Button>
        )}
      </>
    );
  };

  const renderDeviceLabel = (device: Device) => {
    const label = DEVICE_TYPES.find(
      (deviceType) => deviceType.value === device.type
    );
    return (
      <DeviceLabel
        color={label?.color}
        style={{ borderRadius: "0 25px 25px 0px" }}
      >
        {label?.value}
      </DeviceLabel>
    );
  };

  const renderDevice = (device: Device) => (
    <Card
      key={device.key}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.04" }}
      bodyStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => onDeviceSelected(device.id)}
      hoverable
    >
      {renderDeviceLabel(device)}
      <Meta
        title={`Device Name: ${device.name}`}
        description={`Serial Number: ${device.serial_number}`}
      />
    </Card>
  );

  const renderProfileHeading = () => (
    <FlexContainer
      self="center"
      direction="column"
      justify="center"
      align="center"
      rowGap="2em"
    >
      {renderUserInfo()}
      {!ownProfile && renderChangeRoleButton()}
    </FlexContainer>
  );

  const renderUserDevices = () => (
    <FlexContainer direction="column" rowGap="1em">
      <Typography.Title level={2}>
        {ownProfile ? "Your Devices" : `${user?.name}'s Devices`}
      </Typography.Title>
      <GridContainer cols="3" colGap="2em" rowGap="1em">
        {devices.map((device) => renderDevice(device))}
      </GridContainer>
    </FlexContainer>
  );

  return user ? (
    <FlexContainer direction="column" justify="center" rowGap="4em">
      {renderProfileHeading()}
      {devices.length > 0 && renderUserDevices()}
      <UserHistory id={user.id} userName={user.name} />
    </FlexContainer>
  ) : null;
};

export default withLayout(Profile);
