import React from 'react'
import { Row, Col } from 'antd'

import { InputGroupPropTyp } from 'util/types/props.types'

const InputGroup: React.FC<InputGroupPropTyp> = ({ children }) => { 
    const childrenArray = React.Children.toArray(children)

    return (
        <Row
            justify='center'
            gutter={16}
        >
            {
                childrenArray.map((child, index) => (
                    <Col
                        span={24 / childrenArray.length}
                        key={`col-${index}`}
                    >
                        {child}
                    </Col>
                ))
            }
        </Row>
    )
}

export default InputGroup