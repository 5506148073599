import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChartWrapper } from 'styles/styles'
import { ChartPropTypes } from 'util/types/props.types'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const BarChart: React.FC<ChartPropTypes> = ({ width, height, options, data }) => {
    return (
        <ChartWrapper width={width} height={height}>
            <Bar options={options} data={data} />
        </ChartWrapper>
    )
}

export default BarChart