/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'

import { AuthContextProviderPropType, AuthContextType } from 'util/types/context.types'
import { ActiveUser } from 'util/types/generics.types'

export const authContext = React.createContext<AuthContextType | null>(null)

export const AuthContextProvider: React.FC<AuthContextProviderPropType> = ({ children }) => { 
    const [activeUser, setActiveUser] = useState<ActiveUser | null>(null)

    return (
        <authContext.Provider value={{
            activeUser,
            setActiveUser
        }}>
            {children}
        </authContext.Provider>
    )
}