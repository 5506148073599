import React, { useState } from "react";

import {
  AppContextProviderPropType,
  AppContextType,
} from "util/types/context.types";
import { Device, FormVisibilityType, User } from "util/types/generics.types";

export const appContext = React.createContext<AppContextType | null>(null);

export const AppContextProvider: React.FC<AppContextProviderPropType> = ({
  children,
}) => {
  const [formVisibility, setFormVisibility] = useState<FormVisibilityType>({
    add: false,
    return: false,
    assign: false,
    delete: false,
  });
  const [devices, setDevices] = useState<Device[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);

  const showAddDeviceForm = () => {
    setFormVisibility((prevState) => ({
      ...prevState,
      add: !prevState.add,
    }));
  };

  const showAssignDeviceForm = (device: Device | void) => {
    if (device) {
      setSelectedDevice(device);
    } else {
      setSelectedDevice(null);
    }
    setFormVisibility((prevState) => ({
      ...prevState,
      assign: !prevState.assign,
    }));
  };

  const showReturnDeviceForm = (device: Device | void) => {
    if (device) {
      setSelectedDevice(device);
    } else {
      setSelectedDevice(null);
    }
    setFormVisibility((prevState) => ({
      ...prevState,
      return: !prevState.return,
    }));
  };

  const showDeleteDeviceForm = (device: Device | void) => {
    if (device) {
      setSelectedDevice(device);
    } else {
      setSelectedDevice(null);
    }
    setFormVisibility((prevState) => ({
      ...prevState,
      delete: !prevState.delete,
    }));
  };

  return (
    <appContext.Provider
      value={{
        devices,
        users,
        selectedDevice,
        formVisibility,
        setDevices,
        setUsers,
        showAddDeviceForm,
        showAssignDeviceForm,
        showReturnDeviceForm,
        showDeleteDeviceForm,
      }}
    >
      {children}
    </appContext.Provider>
  );
};
