import React from 'react'
import moment from 'moment'
import { LaptopOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons'

import DeviceTypesChart from 'components/charts/DeviceTypesChart'
import DeviceYearsChart from 'components/charts/DeviceAgesChart'
import SearchTable from 'components/common/SearchTable'
import withLayout from 'hocs/withLayout'
import { ReportsPropType } from 'util/types/props.types'
import { Typography, Statistic, Empty } from 'antd'
import { CustomSpin, FlexContainer, GridContainer } from 'styles/styles'
import { DeviceWithDepreciation } from 'util/types/generics.types'

const Reports: React.FC<ReportsPropType> = ({ deviceCount, availableDeviceCount, depreciatedDeviceCount, devices }) => { 
    const responseHasNull = devices === null || depreciatedDeviceCount == null || deviceCount === null || availableDeviceCount === null
    const noDevicesRegistered = deviceCount === 0
   
    const renderTotalDeviceCount = () => {
        return (
            <FlexContainer
                direction='column'
                justify='center'
            >
                <Typography.Title style={{ margin: '0' }} level={2}>Total devices</Typography.Title>
                <Statistic
                    value={deviceCount}
                    valueStyle={{
                        color: '#0A6CD9',
                        fontWeight: 'bold'
                    }}
                    style={{ alignSelf: 'center' }}
                    prefix={<LaptopOutlined />}
                />
            </FlexContainer>
        )
    }

    const renderAvailableDeviceCount = () => {
        return (
            <FlexContainer
                direction='column'
                justify='center'
            >
                <Typography.Title style={{ margin: '0' }} level={2}>Available devices</Typography.Title>
                <Statistic
                    value={availableDeviceCount}
                    valueStyle={{
                        color: '#56AB37',
                        fontWeight: 'bold',
                    }}
                    style={{ alignSelf: 'center' }}
                    prefix={<LikeOutlined />}
                />
            </FlexContainer>
        )
    }
    
    const renderDepreciatedDeviceCount = () => {
        return (
            <FlexContainer
                direction='column'
                justify='center'
            >
                <Typography.Title style={{ margin: '0' }} level={2}>Depreciated devices</Typography.Title>
                <Statistic
                    value={depreciatedDeviceCount}
                    valueStyle={{
                        color: '#FF0000',
                        fontWeight: 'bold'
                    }}
                    style={{ alignSelf: 'center' }}
                    prefix={<DislikeOutlined />}
                />
            </FlexContainer>
        )
    }

    const renderNumericReports = () => {
        return (
            <FlexContainer
                direction='row'
                justify='center'
                align='center'
                colGap='2em'
            >
                {renderTotalDeviceCount()}
                {renderAvailableDeviceCount()}
                {renderDepreciatedDeviceCount()}
            </FlexContainer>
        )
    }

    const renderChartReports = () => {
        return (
            <FlexContainer
                direction='column'
            >
                <Typography.Title
                    level={2}
                    style={{ alignSelf: 'center' }}
                >
                    Breakdown on Device Ages / Types
                </Typography.Title>
                <GridContainer
                    cols='2'
                    rowGap='5em'
                    centered
                >
                    <DeviceYearsChart />
                    <DeviceTypesChart />
                </GridContainer>
            </FlexContainer>
        )
    }

    const renderPurchaseDate = (text: string) => {
        return moment(text).format('YYYY-MM-DD')
    }

    const renderOwnerName = (text: string) => {
        return text ?? 'Unassigned'
    }
    
    const renderDepreciationReport = () => (
        <FlexContainer
            direction='column'
            justify='center'
            align='center'
            colGap='2em'
        >
            <Typography.Title level={2}>Device Depreciation Report</Typography.Title>
            <SearchTable
                columns={columns}
                dataSource={devices}
                searchColumns={['deviceOwner']}
            />
        </FlexContainer>
    )

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'Owner',
            dataIndex: 'deviceOwner',
            key: 'deviceOwner',
            render: (record: string) => renderOwnerName(record)
        },

        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },

        {
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            onFilter: (value: string | number | boolean, record: DeviceWithDepreciation) => record.type !== null && record.type.indexOf(value as string) === 0
        },

        {
            title: 'Purchase Date',
            dataIndex: 'purchase_date',
            key: 'purchase_date',
            render: (record: string) => renderPurchaseDate(record)
        },

        {
            title: 'Depreciated Value',
            dataIndex: 'depreciatedValue',
            key: 'depreciatedValue',
        },

        {
            title: 'Actual Value',
            dataIndex: 'actualValue',
            key: 'actualValue',
        },

        {
            title: 'Time until depreciation (months)',
            dataIndex: 'depreciatedInMonths',
            key: 'depreciatedInMonths',
            filters: [
                { text: '6 months', value: 6 },
                { text: '3 months', value: 3 },
                { text: 'Depreciated', value: 0 }
            ],
            onFilter: (value: string | number | boolean, record: DeviceWithDepreciation) => record.depreciatedInMonths !== null && record.depreciatedInMonths === (value as number)
        },
    ]

    if (responseHasNull) { 
        return <CustomSpin size='large'/>
    }

    if (noDevicesRegistered) {
        return <Empty />
    }

    return (
        <GridContainer
            rowGap='7em'
            mb='3em'
        >
            {renderNumericReports()}
            {renderChartReports()}
            {renderDepreciationReport()}
        </GridContainer>
    )
}

export default withLayout(Reports)