import React from 'react'

import { AppWrapper, BodyWrapper, NavWrapper } from 'styles/styles'
import { LayoutnPropType } from 'util/types/props.types'

const Layout: React.FC<LayoutnPropType> = ({ navigation, body }) => { 
    return (
        <AppWrapper>
            <NavWrapper>{navigation}</NavWrapper>
            <BodyWrapper>{body}</BodyWrapper>
        </AppWrapper>
    )
}

export default Layout