import { DEVICES_PATH, REPORTS_PATH, ROOT_PATH, USERS_PATH } from './paths'

export const DEVICE_TYPES = [
    { value: 'laptop', color: 'magenta', pieColor: 'rgba(75, 192, 192, 0.2)', pieBorderColor: 'rgba(75, 192, 192, 1)' },
    { value: 'tablet', color: 'blue', pieColor: 'rgba(255, 99, 132, 0.2)', pieBorderColor: 'rgba(255, 99, 132, 1)' },
    { value: 'phone', color: 'cyan', pieColor: 'rgba(54, 162, 235, 0.2)', pieBorderColor: 'rgba(54, 162, 235, 1)' },
    { value: 'desktop', color: 'orange', pieColor: 'rgba(255, 206, 86, 0.2)', pieBorderColor: 'rgba(255, 206, 86, 1)' },
    { value: 'server', color: 'gold', pieColor: 'rgba(153, 102, 255, 0.2)', pieBorderColor: 'rgba(153, 102, 255, 1)' },
    { value: 'other', color: 'geekblue', pieColor: 'rgba(255, 159, 64, 0.2)', pieBorderColor: 'rgba(255, 159, 64, 1)' }
]

export const DEVICE_STATES = [
    { value: 'assigned', color: 'green' },
    { value: 'unassigned', color: 'volcano' },
]

export enum USER_ROLES {
    ADMIN = 'admin',
    USER = 'user'
}

export enum CONFIRMATION_TYPES { 
    RECEIVED = 'received',
    RETURNED = 'returned'
}

export const NAVIGATION_LINKS = [
    { path: ROOT_PATH, name: 'Profile' },
    { path: USERS_PATH, name: 'Users' },
    { path: DEVICES_PATH, name: 'Devices' },
    { path: REPORTS_PATH, name: 'Reports' }
]