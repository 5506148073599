import React, { useEffect, useState } from 'react'

import withAuth from 'hocs/withAuth'
import withError from 'hocs/withAuth'
import Reports from 'views/Reports'
import { getDevicesDepreciation, getTotalAvailableDeviceCount, getTotalDeviceCount } from 'api/api'
import { DeviceWithDepreciation } from 'util/types/generics.types'

const ReportsContainer: React.FC = () => { 
    const [devicesWithDepreciation, setDevicesWithDepreciation] = useState<DeviceWithDepreciation[] | null>(null)
    const [depreciatedDeviceCount, setDepreciatedDeviceCount] = useState<number | null>(null)
    const [deviceCount, setDeviceCount] = useState<number | null>(null)
    const [availableDeviceCount, setAvailableDeviceCount] = useState<number | null>(null)

    const getDeivcesDepreciationReport = async () => { 
        const response = await getDevicesDepreciation()
        if (response !== 'error') { 
            const devicesWithDepreciation = response.map(device => ({key: device.id, ...device}))
            const depreciatedDeviceCount = devicesWithDepreciation.filter(device => device.isDepreciated).length
            setDevicesWithDepreciation(devicesWithDepreciation)
            setDepreciatedDeviceCount(depreciatedDeviceCount)
        }
    }

    const getDeviceCount = async () => { 
        const response = await getTotalDeviceCount()
        if (response !== 'error') {
            setDeviceCount(response)
        }
    }

    const getAvailableDeviceCount = async () => { 
        const response = await getTotalAvailableDeviceCount()
        if (response !== 'error') { 
            setAvailableDeviceCount(response)
        }
    }

    useEffect(() => { 
        getDeivcesDepreciationReport()
        getDeviceCount()
        getAvailableDeviceCount()
    }, [])

    return (
        <Reports 
            devices={devicesWithDepreciation}
            depreciatedDeviceCount={depreciatedDeviceCount}
            deviceCount={deviceCount}
            availableDeviceCount={availableDeviceCount}
        />
    )
}

export default withError(withAuth(ReportsContainer))