/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";

import Logo from "./Logo";
import authService from "services/AuthService";
import { NavMenuWrappe, CustomNavLink, LogoutButton } from "styles/styles";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "util/paths";
import { authContext } from "contexts/AuthContext";
import { AuthContextType } from "util/types/context.types";
import { NAVIGATION_LINKS } from "util/constants";
import { hasAccessToPath } from "AppRoutes";

const Navigation: React.FC = () => {
  const { setActiveUser } = useContext(authContext) as AuthContextType;
  const navigate = useNavigate();
  const navigationLinks = NAVIGATION_LINKS.map((link) =>
    hasAccessToPath(link) ? (
      <CustomNavLink className="nav-link" to={link.path} key={link.name}>
        {link.name}
      </CustomNavLink>
    ) : (
      ""
    )
  );

  const logout = async () => {
    await authService.logout();
    setActiveUser(null);
    navigate(LOGIN_PATH);
  };

  const renderNavMenu = () => {
    return (
      <NavMenuWrappe cols={navigationLinks.filter((link) => link).length + 1}>
        {navigationLinks}
        <LogoutButton onClick={logout}>LOGOUT</LogoutButton>
      </NavMenuWrappe>
    );
  };

  return (
    <>
      <Logo size="normal" vertical={false} />
      {renderNavMenu()}
    </>
  );
};

export default Navigation;
